<template>
  <div eagle-form-item="text">
    <v-text-field
      dense
      outlined
      :readonly="readonly"
      :disabled="disabled"
      :maxlength="maxlength"
      :error="!!error"
      :error-messages="error"
      :rules="vuetifyRules"
      :autofocus="focus"
      :type="inputType"
      :counter="!disabled && !readonly"
      v-model="data"
    >
      <template v-slot:append>
        <v-icon
          v-if="password"
          @click="viewPasswordMode = !viewPasswordMode"
        >
          fa fa-eye
        </v-icon>
      </template>

      <template #append-outer>
        <v-tooltip top>
          <template #activator="{on}">
            <v-icon
              v-on="on"
              v-if="randomUid"
              class="cursor-pointer"
              @click="createUid"
            >
              fa fa-random
            </v-icon>
          </template>
          <div>{{'action.create_random_uid'| t}}</div>
        </v-tooltip>
      </template>
    </v-text-field>

    <div v-if="passwordStrengthLevel && validatePasswordStrength">
      <v-progress-linear
        stream
        :value="passwordStrengthProgressValue"
        :color="passwordStrengthColor"
      ></v-progress-linear>
      <div>{{passwordStrengthText}}</div>
    </div>

    <v-img
      v-if="thumbnail"
      width="100"
      height="100"
      :src="thumbnail"
    ></v-img>
  </div>
</template>

<script lang="babel" type="text/babel">
import formTextType from '@/components/form/formItemTypes/formTextType'
import uuidV4 from 'uuid/v4'
export default {
  mixins: [formTextType],
  data: () => ({
    viewPasswordMode: false,
  }),
  methods: {
    createUid() {
      this.data = uuidV4()
    },
  },
  computed: {
    validatePasswordStrength() {
      if(!this.password) return false
      return this.formDataItem.validatePasswordStrength === true
    },
    passwordStrengthLevel() {
      return this.$helper.checkPasswordStrengthLevel(this.data)
    },
    passwordStrengthColor() {
      if(this.passwordStrengthLevel === 'strong') return 'green'
      if(this.passwordStrengthLevel === 'medium') return 'orange'
      return 'red'
    },
    passwordStrengthProgressValue() {
      if(this.passwordStrengthLevel === 'strong') return 100
      if(this.passwordStrengthLevel === 'medium') return 60
      return 30
    },
    passwordStrengthText() {
      return this.$t(`password.strength.level.${this.passwordStrengthLevel}`)
    },
    thumbnail() {
      if(typeof this.formDataItem.thumbnail != 'function') return ''
      return this.formDataItem.thumbnail(this.formInfo, this.$eagleLodash.cloneDeep(this.storeData)) || ''
    },
    password() {
      return this.formDataItem.password === true
    },
    randomUid() {
      return this.formDataItem.randomUid === true
    },
    inputType() {
      if(this.viewPasswordMode) return 'text'
      if(this.password) return 'password'
      if(this.formDataItem.inputType) return this.formDataItem.inputType
      return 'text'
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
